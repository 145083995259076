<template>
  <div class="live h-full px-3 text-white">
    <div class="live-head mt-3">
      <div class="live-title flex items-center mb-3">
        <h1 class="flex items-center text-xl">
          <div class="animate-ping rounded-full h-4 w-4 bg-red-500 absolute"></div>
          <div class="rounded-full h-4 w-4 bg-red-500 mr-2"></div>
          Live inschrijvingen
        </h1>
        <div class="live-order-info hidden md:block text-center bg-white bg-opacity-30 rounded-full py-1 px-2 ml-3">
          <div v-if="this.orders" class="live-info inline-flex w-full">
            <span class="px-3 border-r-2"><span class="text-sm"><strong>{{website.completed}}</strong>{{'/' + website.total}}</span>  <span clas="text-sm">Website</span></span>
            <span class="px-3 border-r-2"><span class="text-sm"><strong>{{ios.completed}}</strong>{{'/' + ios.total}}</span> <span clas="text-sm">iOS</span></span>
            <span class="px-3"><span class="text-sm"><strong>{{android.completed}}</strong>{{'/' + android.total}}</span> <span clas="text-sm">Android</span></span>
          </div>
      </div>
        <span class="text-sm dubline-yellow dubline-text rounded-full ml-auto md:ml-3 py-1 px-3"><strong class="dubline-dark-text">vandaag {{completed}}</strong> {{'/' +total}}</span>
      </div>
      <div class="live-order-info  md:hidden text-center bg-white bg-opacity-30 rounded-full py-2">
        <div v-if="this.orders" class="live-info inline-flex w-full">
          <span class="flex-1 px-3 border-r-2"><span class="text-2xl"><strong>{{website.completed}}</strong>{{'/' + website.total}}</span> <br> <span clas="text-sm">Website</span></span>
          <span class="flex-1 px-3 border-r-2"><span class="text-2xl"><strong>{{ios.completed}}</strong>{{'/' + ios.total}}</span> <br> <span clas="text-sm">iOS</span></span>
          <span class="flex-1 px-3"><span class="text-2xl"><strong>{{android.completed}}</strong>{{'/' + android.total}}</span> <br> <span clas="text-sm">Android</span></span>
        </div>
      </div>
    </div>
  
    <div class="flying-field h-5/6 relative">
        <div class="kite absolute" v-for="kite in kites" :key="kite.id" :class="kite.class" :style="{left: kite.left + '%', top: kite.previousStep + '%'}">
          <img class="wiggle" :class="'delay-' + kite.delay" :src="require(`@/assets/images/${kite.device}-glider.svg`)">
        </div>
    </div>
  </div>


</template>

<script>
export default {
  data() {
    return {
      website: [],
      ios: [],
      android: [],
      kites: [],
      orders: [],
      ordersNew: [],
      total: '',
      completed: '',
    }
  },
  
  methods: {
    getLiveInfo()
    {
      this.$api.get('https://provisioning.dubline.nl/test/rocketeer/poll2')
        .then(response => {
          this.completed = response.data.website.completed + response.data.ios.completed + response.data.android.completed;
          this.total = response.data.website.total + response.data.ios.total + response.data.android.total;
          this.website = response.data.website;
          this.ios = response.data.ios;
          this.android = response.data.android;
          this.orders = response.data.website.orders.concat(response.data.ios.orders.concat(response.data.android.orders));
          // check which orders quit and clean them from the kite object
          this.cleanKites(this.orders);
          // loop over orders and check if they exist, if yes update them else we create a new one
          this.orders.forEach(order => {
            let kite = this.findKite(order.id);
            if(!kite)
            {
              kite = {
                id: order.id,
                device: order.platform,
                left: Math.floor(Math.random() * 80),
                delay: Math.floor(Math.random() * 6),
                previousStep: 0,
              }
              this.kites.push(kite)
            }
            else 
            {
              kite.previousStep = kite.currentStep;
            }
            kite.class = 'kite-progress-' + order.procent;
            kite.currentStep = order.procent;
            if(order.procent == 100)
            {
              // remove the kite after 5 seconds for animation purposes
              setTimeout(this.removeKite(order.id), 5000);
            }
            
          });
        })
        .catch(error => {
          console.log(error)
        })
    },

    cleanKites(orders)
    {
      // check the orders list for aborted orders and clear the kite with the same order number that is missing
      let orderIds = [];
      orders.forEach(order => {
        orderIds.push(order.id);
      })
      this.kites.forEach(k => {
        if(!orderIds.includes(k.id))
        {
          this.removeKite(k.id);
        }
      })
    },

    findKite(id)
    {
      // look up kite with the same id
      let kite = false;
      this.kites.forEach(k => {
        if(k.id == id)
        {
          console.log(k);
          kite = k;
          return;
        }
      });
      return kite;
    },

    removeKite(id)
    {
      let index = this.kites.findIndex(kite => kite.id == id);
      this.kites.splice(index, 1);
    },

    addTestKites()
    {
      this.kites = [{
        "id": "144",
        "device": "android",
        "left": 34,
        "delay": 4,
        "previousStep": 40,
        "class": "kite-progress-40",
        "currentStep": 40
      }, {
        "id": "123",
        "device": "ios",
        "left": 0,
        "delay": 0,
        "previousStep": 60,
        "class": "kite-progress-60",
        "currentStep": 60
      }, {
        "id": "155",
        "device": "website",
        "left": 12,
        "delay": 2,
        "previousStep": 60,
        "class": "kite-progress-60",
        "currentStep": 60
      }]
    }
  },
  mounted()
  {
    this.getLiveInfo();
    window.setInterval(() => {
      this.getLiveInfo()
    }, 15000)
  }
}
</script>
